import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { NGXLogger } from 'ngx-logger';
import { QueryStringService } from './core/services/query-string.service';
import { Portal } from './shared/domains/scheduler/portal';
import { Scheduler } from './shared/domains/scheduler/scheduler';
import { PORTAL_SCHEDULER_API } from './shared/constants';
import { LanguageService } from './core/services/language.service';
import { LoggerUpdateService } from './core/services/logger-update.service';
import { AppConfigService } from './core/services/app-config.service';
import { ApiQueryService } from './core/data-services/api-query.service';
import { NavService } from './core/services/nav.service';
import { AppointmentDetailsService } from './core/services/appointment-details.service';

/**
 * This service is responsible for initializing the application, fetching the
 * portal and scheduler objects.
 * It is called from the app.module.ts file via the APP_INITIALIZER token.
 */
@Injectable({
  providedIn: 'root',
})
export class AppInitService {
  private initialized$ = new BehaviorSubject<boolean>(false);

  constructor(
    private logger: NGXLogger,
    private queryStringService: QueryStringService,
    private languageService: LanguageService,
    private loggerUpdateService: LoggerUpdateService,
    private appConfigService: AppConfigService,
    private apiQueryService: ApiQueryService,
    private navService: NavService,
    private appointmentDetailsService: AppointmentDetailsService,
  ) {}

  initialize(): Observable<void> {
    this.logger.debug('AppInitService: Initialization started');

    // Initialize query string service
    this.queryStringService.initialize();

    // Initialize language settings
    const language: string | null = this.queryStringService.getLanguage();
    this.languageService.initializeLanguage(language);

    // enable browser logging if logging=on is in the query string
    if (this.queryStringService.getParamValue('logging') === 'on') {
      this.loggerUpdateService.enableBrowserLogging();
    }

    // Do we have either domain name or practice.portal_uid? If not, throw an error
    let qsDomainName: string | null = null;
    let qsPuid: string | null = null;
    try {
      // if we don't have a domain name, getting it will error
      qsDomainName = this.queryStringService.getDomainName();
    } catch {
      // do nothing
    }
    try {
      // if we don't have a puid, getting it will error
      qsPuid = this.queryStringService.getPortalUid();
    } catch {
      // do nothing
    }
    if (!qsDomainName && !qsPuid) {
      this.logger.warn('No domain name or puid found in query string');
      this.logger.error('Invalid initialization.');
      this.handleInitializationError();
      return of(void 0);
    }

    // Continue with fetching portal and scheduler objects
    return this.fetchPortalAndSchedulerObjects(qsDomainName, qsPuid).pipe(
      tap(() => {
        this.logger.debug('portalObj', this.appConfigService.getPortal());
        this.logger.debug('schedulerObj', this.appConfigService.getScheduler());
      }),
      map(() => {
        this.logger.debug('## AppInitService: Initialization completed');
        this.initialized$.next(true);
        return void 0; // Transform the emitted value to `void`
      }),
      catchError((error) => {
        this.logger.error(
          'initialize(): Error in initialization process',
          error,
        );
        this.handleInitializationError();
        return of(void 0);
      }),
    );
  }

  private handleInitializationError(): void {
    this.navService.navigateToErrorPage();
  }

  isInitialized(): Observable<boolean> {
    return this.initialized$.asObservable();
  }

  getInitialized(): boolean {
    return this.initialized$.value;
  }

  private fetchPortalAndSchedulerObjects(
    domainName: string | null,
    puid: string | null,
  ): Observable<Scheduler> {
    return this.fetchPortal(domainName, puid).pipe(
      switchMap((portal) => {
        // Store portal object
        this.appConfigService.setPortal(portal);

        const schedulerLinkObj = portal.links.find(
          (link) => link.rel === PORTAL_SCHEDULER_API,
        );
        if (!schedulerLinkObj) {
          // If no scheduler link is found, throw an error or handle accordingly
          return throwError(() => new Error('No scheduler link found'));
        }
        return this.fetchScheduler(schedulerLinkObj.href);
      }),
      switchMap((scheduler) => {
        // Store scheduler object
        this.appConfigService.setScheduler(scheduler);
        this.checkForSingleOffice();

        return of(scheduler); // You can adjust what to return here based on your needs
      }),
      catchError((error) => {
        this.logger.error(
          'fetchPortalAndSchedulerObjects(): Error in initialization process',
          error,
        );
        this.logger.warn('CHECK IF PORTAL OBJ URL IS WORKING IN BROWSER.');
        return throwError(() => error);
      }),
    );
  }

  private fetchPortal(
    domainName: string | null,
    puid: string | null,
  ): Observable<Portal> {
    if (domainName) {
      return this.apiQueryService.fetchPortalFromDomainName(domainName);
    } else if (puid) {
      return this.apiQueryService.fetchPortalFromPortalId(puid);
    } else {
      return throwError(() => new Error('No domain name or portal id found'));
    }
  }

  private fetchScheduler(link: string): Observable<Scheduler> {
    return this.apiQueryService.fetchScheduler(link);
  }

  // Check the scheduler object and check the size of the offices array.
  private checkForSingleOffice(): void {
    const scheduler = this.appConfigService.getScheduler();
    this.appointmentDetailsService.isSingleLocation =
      scheduler.offices?.length === 1;
  }
}
