<input
  #checkbox
  [ngClass]="{ disabledBtn: disabled }"
  [disabled]="disabled"
  type="checkbox"
  id="{{ labelName }}"
  (click)="toggle()"
  aria-pressed="false"
/>
<label class="checkbox pl-2">{{ labelName ? labelName : '' }}</label>
<pre *ngIf="environment.useDebugMode">isMultiCheckboxChecked: {{ isOn }}</pre>
