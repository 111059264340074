import { Injectable } from '@angular/core';
import { catchError, from, mergeAll, Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { map, shareReplay, tap } from 'rxjs/operators';
import {
  ApiAppointment,
  ApiPatientAppointmentResult,
} from '../../shared/domains/scheduler/api-appointment';
import { Portal } from '../../shared/domains/scheduler/portal';
import { environment } from '../../../environments/environment';
import { Scheduler } from '../../shared/domains/scheduler/scheduler';
import { AppointmentRequestPatient } from '../../shared/domains/scheduler/appointment-request-patient';
import { PatientSearchResult } from '../../shared/domains/scheduler/patient-search/patient-search-result';
import { ApiAvailableAppointments } from '../../shared/model/appointment.model';
import { NavService } from '../services/nav.service';
import { DynamicForm, DynamicFormConfig, DynamicFormContent } from 'openapi';
import { AppointmentV2 } from 'openapi';

@Injectable({
  providedIn: 'root',
})
export class ApiQueryService {
  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private navService: NavService,
  ) {}

  private handleError(error: HttpErrorResponse): Observable<never> {
    this.logger.error('ApiQueryService: An HTTP error occurred:', error);
    this.logger.error('ApiQueryService: Navigating to error page');
    this.navService.navigateToErrorPage();
    return throwError(() => error);
  }

  fetchPortalFromDomainName(domainName: string): Observable<Portal> {
    return this.http
      .get<Portal>(`${environment.apiUrl}portals?domain_name=${domainName}`)
      .pipe(catchError(this.handleError.bind(this)));
  }
  fetchPortalFromPortalId(portalId: string): Observable<Portal> {
    return this.http
      .get<Portal>(`${environment.apiUrl}portals/${portalId}`)
      .pipe(catchError(this.handleError.bind(this)));
  }

  fetchScheduler(link: string): Observable<Scheduler> {
    return this.http
      .get<Scheduler>(link)
      .pipe(catchError(this.handleError.bind(this)));
  }

  putAddBookAppointment$(
    link: string,
    appointment: ApiAppointment,
  ): Observable<ApiAppointment> {
    this.logger.debug('in ApiQueryService.putAddBookAppointment$', link);
    return this.http
      .put<ApiAppointment>(link, appointment)
      .pipe(catchError(this.handleError.bind(this)));
  }

  putUpdateConfirmedAppointment$(
    link: string,
    appointment: ApiAppointment,
  ): Observable<ApiAppointment> {
    this.logger.debug(
      'in ApiQueryService.putUpdateConfirmedAppointment$',
      link,
    );
    return this.http
      .put<ApiAppointment>(link, appointment)
      .pipe(catchError(this.handleError.bind(this)));
  }

  postAddUnconfirmedAppointment(
    link: string,
    appointment: ApiAppointment,
  ): Observable<ApiAppointment> {
    this.logger.debug('in ApiQueryService.postAddUnconfirmedAppointment', link);
    return this.http
      .post<ApiAppointment>(link, appointment)
      .pipe(catchError(this.handleError.bind(this)));
  }

  patientSearch(
    patientSearchLink: string,
    patient: AppointmentRequestPatient,
  ): Observable<PatientSearchResult> {
    this.logger.debug('ApiQueryService.patientSearch');
    return this.http
      .post<PatientSearchResult>(patientSearchLink, patient)
      .pipe(catchError(this.handleError.bind(this)));
  }

  fetchAvailableAppointments(
    link: string,
  ): Observable<ApiAvailableAppointments> {
    this.logger.debug('ApiQueryService.fetchAvailableAppointments');
    return this.http
      .get<ApiAvailableAppointments>(link)
      .pipe(shareReplay(), catchError(this.handleError.bind(this)));
  }

  fetchPatientAppointments(
    link: string,
  ): Observable<ApiPatientAppointmentResult[]> {
    this.logger.debug('ApiQueryService.fetchPatientAppointments');
    return this.http
      .get<ApiPatientAppointmentResult[]>(link)
      .pipe(catchError(this.handleError.bind(this)));
  }

  fetchPatientFormsIndex(link: string): Observable<DynamicFormConfig[]> {
    this.logger.debug('ApiQueryService.fetchPatientFormsIndex');
    return this.http
      .get<DynamicFormConfig[]>(link)
      .pipe(catchError(this.handleError.bind(this)));
  }

  fetchPatientFormData(link: string): Observable<DynamicFormContent> {
    this.logger.debug('ApiQueryService.fetchPatientFormData');
    return this.http.get<DynamicFormContent>(link).pipe(
      tap((response) => {
        this.logger.debug('HTTP response:', response);
      }),
      catchError(this.handleError.bind(this)),
    );
  }

  appointmentReschedule(
    link: string,
    apptReqObj: AppointmentV2,
  ): Observable<void> {
    this.logger.debug(
      'ApiQueryService.appointmentReschedule',
      link,
      apptReqObj,
    );
    return this.http
      .put<void>(link, apptReqObj)
      .pipe(catchError(this.handleError.bind(this)));
    // To emulate a successful response, return of(void 0)
  }

  appointmentCancel(link: string): Observable<void> {
    this.logger.debug('ApiQueryService.appointmentCancel');
    return this.http
      .delete<void>(link)
      .pipe(catchError(this.handleError.bind(this)));
    // To emulate a successful response, return of(void 0)
  }

  // Fetches the form content from a local file.
  fetchPatientFormDataFromFile(_link: string): Observable<DynamicFormContent> {
    this.logger.debug('ApiQueryService.fetchPatientFormDataFromFile');

    // Read 'local-form-wrapper.json', which is in the form of DynamicFormContent.
    // Then read 'db-pre-visit-form.json', and assign to the 'content' property.
    return from(import('../../forms/local-form-wrapper.json')).pipe(
      map((scaffoldData) => {
        const scaffold = scaffoldData as unknown as DynamicFormContent;
        return from(import('../../forms/db-pre-visit-form.json')).pipe(
          map((contentData) => {
            return {
              ...scaffold,
              content: contentData as unknown as DynamicForm,
            };
          }),
          catchError(this.handleError.bind(this)),
        );
      }),
      catchError(this.handleError.bind(this)),
      mergeAll(),
    );
  }

  savePatientDynamicForm(
    link: string,
    requestBody: DynamicFormContent,
  ): Observable<DynamicFormContent> {
    this.logger.debug('ApiQueryService.savePatientDynamicFrom');

    return this.http
      .post<DynamicFormContent>(link, requestBody)
      .pipe(catchError(this.handleError.bind(this)));
    // To emulate a successful response, return of(requestBody)
  }
}
